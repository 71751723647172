import '../Monitor.css';

import React, { Component } from 'react';

import Colors from './Colors.js';
import JourneyCanceledMessage from './JourneyCanceledMessage.jsx';
import JourneyMessage from './JourneyMessage.jsx';
import { getFormattedTime } from './Utilities';

class SmallTrainJourneyItem extends Component {
  renderIcon() {
      var color = this.props.journey.type === "Pågatåg" || this.props.journey.type === "Pågatågen" || this.props.journey.type === "Pågatåg Express" ? Colors.PurpleTrain : Colors.Train;
    return (   
      <div className="iconContainer">
          <svg className="svgConnTypeTitleIcon" style={{'fill': color}} version="1.1" x="0px" y="0px" width="49px" height="50px" viewBox="0 0 86 91">
          <path d="M73.3,22.9c-0.1-1.9-0.5-9.5-6.5-13.4c-3.1-2-6.1-2.1-12.2-2.3h-0.8C52.2,3,48.2,0,43.4,0s-8.8,3-10.3,7.2h-0.6 c-6.1,0.2-9.1,0.3-12.2,2.3c-6.1,3.9-6.4,11.5-6.5,13.4c-0.1,1.9-3.7,41.7-3.7,41.7c0.2,2.1,0.7,6.6,6.4,10.2 c3.2,2,9.4,3.1,15.5,3.3h23.2c6.1-0.2,12.3-1.4,15.5-3.3c5.6-3.5,6.1-8.1,6.4-10.2C77,64.6,73.4,24.8,73.3,22.9z M43.4,5.5 c3.1,0,5.6,2.5,5.6,5.6s-2.5,5.6-5.6,5.6s-5.6-2.5-5.6-5.6S40.3,5.5,43.4,5.5z M21,69.2c-3.1,0-5.6-2.5-5.6-5.6 c0-3.1,2.5-5.6,5.6-5.6s5.6,2.5,5.6,5.6C26.5,66.7,24,69.2,21,69.2z M60.1,32.3c0,1.1-2,14.3-2,14.3c-0.1,1.2-0.4,3.5-3.6,5.5 c-1.8,1.1-5.2,1.6-8.7,1.7H41c-3.5-0.1-6.9-0.6-8.7-1.7c-3.2-2-3.5-4.3-3.6-5.5c0,0-1.9-13.2-2-14.3c0-1.1-1-5.2,2.9-7.7 c1.8-1.1,3.5-1.2,7.1-1.4h13.5c3.5,0.1,5.2,0.2,7.1,1.4C61.1,27.1,60.1,31.2,60.1,32.3z M65.7,69.2c-3.1,0-5.6-2.5-5.6-5.6 c0-3.1,2.5-5.6,5.6-5.6c3.1,0,5.6,2.5,5.6,5.6C71.3,66.7,68.8,69.2,65.7,69.2z"/>
          <path d="M62.6,80.9h-0.7c-1.7,0.2-3.3,0.3-5,0.4H30.3c-1.7-0.1-3.4-0.2-5-0.4h-0.8l0.1-0.1c-3.9-0.5-7.4-1.3-10-2.4 c-0.3,0.2-0.6,0.5-0.8,0.7l-6.2,7.7c-0.9,1.1-0.6,2,0.6,2l8.5,0c1.2,0,2.2-0.9,3.1-2l0.5-0.5H67l0.5,0.5c0.9,1.1,1.8,2,3.1,2l8.5,0 c1.2,0,1.5-0.9,0.6-2l-6.2-7.7c-0.2-0.3-0.5-0.5-0.7-0.7C69.9,79.5,66.4,80.3,62.6,80.9L62.6,80.9z"/>
        </svg>
      </div>
    );
  }

  renderInfoMessage(){
    return (
      <tr>
        <td colSpan="7">
          <JourneyMessage text={this.props.journey.message} width={this.props.width}/>
        </td>
      </tr>
    );
  }

  renderCanceledMessage(){
    return (
      <JourneyCanceledMessage textCols={4} canceledSignCols={2} text={this.props.journey.message} width={this.props.width}/>
    );
  }

  renderMessage() {
    if (this.props.journey.canceled) {
      return this.renderCanceledMessage();
    }
    else if (this.props.journey.message){
      return this.renderInfoMessage();
    }
    return null;
  }

  getBackgroundColor() {
    return this.props.index % 2 !== 0 ? "#ffffff" : "#e2e5ec";
  }

  render() {
    const directionMaxDefaultWidth = 550
    var time = getFormattedTime(this.props.journey.time);
    var updatedTime = this.props.journey.updatedTime ? getFormattedTime(this.props.journey.updatedTime) : "";

    var directionMaxWidth = (this.props.width || directionMaxDefaultWidth) - directionMaxDefaultWidth;

    return (
      <tbody style={{background: this.getBackgroundColor()}}>
        <tr className="JourneyItem">
    	    <td className="JourneyItemIcon" style={{maxWidth: 60}}>{this.renderIcon()}</td>
          <td className="Direction" style={{maxWidth: directionMaxWidth}}>{this.props.journey.direction}</td>
          <td className={this.props.journey.canceled || this.props.journey.updatedStopPoint ? "changed" : ""}>{this.props.journey.platform}</td>
          <td className={this.props.journey.canceled ? "changed" : ""}>{this.props.journey.updatedStopPoint}</td>
          <td className={this.props.journey.updatedTime || this.props.journey.canceled || this.props.journey.awaitTime ? "changed" : ""}>{time}</td>
          <td className="UpdatedTime">{updatedTime}</td>
        </tr>
        {this.renderMessage()}
      </tbody>
    );
  }
}

export default SmallTrainJourneyItem;
