import React, { Component } from 'react';
import {logo} from '../Images.js'

class Footer extends Component {
  render() {
  	const dateToString = d => `${d.getHours()}:${('00' + (d.getMinutes())).slice(-2)}` 

    return (
      <div className="Footer">
    	<div style={{'float': 'left'}}>{dateToString(new Date())}</div>
        <div style={{'float': 'right'}}><img style={{height: 25, paddingBottom: 6}} src={logo} alt=""/></div>
      </div>
    );
  }
}

export default Footer;
