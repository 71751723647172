import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App/App';
import ConfigApp from './components/Config/ConfigApp';
import { register as registerServiceWorker } from './registerServiceWorker';

var cleanUri = function(uri) {
    return uri
        .replace(/%C5/ig, "%C3%85")
        .replace(/%C4/ig, "%C3%84")
        .replace(/%D6/ig, "%C3%96")
        .replace(/%E5/ig, "%C3%A5")
        .replace(/%E4/ig, "%C3%A4")
        .replace(/%F6/ig, "%C3%B6");
}

if (window.location.href.indexOf('selSingleStationKey') >= 0 || window.location.href.indexOf('cmdAction') >= 0) {
    var createNewQuery = function(urlParams) {
        const stationId = urlParams.get('selSingleStationKey');
        const stationName = urlParams.get('selSingleStation');
        const trafficTypes = urlParams.get('TrafficMask');
        const departure = urlParams.get('selDirection');
        const stopPoint = urlParams.get('inpStop');

        return "?stationId=" + stationId + "&stationName=" + stationName + "&trafficTypes=[" + trafficTypes.replace(/%%2c/g, ',').replace(/%%,/g, ',').replace(/%,/g, ',') + "]&departure=" + departure + "&stopPoint=" + stopPoint;
    }
    var url = cleanUri(window.location.search);

    var newQuery = createNewQuery(new URLSearchParams(url));
    window.location.replace(encodeURI(window.location.origin + newQuery));
} else {
    const rootElement = document.getElementById('root');
    const urlParams = new URLSearchParams(cleanUri(window.location.search));
    const stationId = urlParams.get('stationId');
    const stationName = urlParams.get('stationName');
    const trafficTypes = urlParams.get('trafficTypes');
    const departure = urlParams.get('departure');
    const stopPoint = urlParams.get('stopPoint');
    const currentTime = urlParams.get('currentTime');
    const apiKey = urlParams.get('apiKey')

    // remove api from browser address field
    //urlParams.delete('apiKey');
    //window.history.replaceState(null, null, window.location.pathname + "?" + urlParams.toString());

    if (stationId && window.location.href.indexOf('downloadmonitor') < 0) {
        ReactDOM.render( <
            App stationId = {
                stationId
            }
            stationName = {
                stationName
            }
            trafficTypes = {
                trafficTypes
            }
            departure = {
                departure
            }
            stopPoint = {
                stopPoint
            }
            currentTime = {
                currentTime
            }
            apiKey = {
                apiKey
            }
            />,
            rootElement);
    } else {
        ReactDOM.render( <
            ConfigApp / > ,
            rootElement);
    }
}

registerServiceWorker();
