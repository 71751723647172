import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { CanceledBox } from './CanceledBox';

class JourneyCanceledMessage extends Component {
  render() {
    return (
      <tr>
      <td colSpan={this.props.textCols} className="JourneyCanceledMessage">
        	<div className="CanceledText">{this.props.text}</div>
      </td>
      {this.props.showCanceledSign? (<td colSpan={this.props.canceledSignCols} style={{textAlign: 'right'}}><CanceledBox/></td>): null}
      </tr>
    );
  }
}

JourneyCanceledMessage.propTypes = {
  text: PropTypes.string,
  canceledSignCols: PropTypes.number,
  textCols: PropTypes.number
};

JourneyCanceledMessage.defaultProps = {
  showCanceledSign: true
};

export default JourneyCanceledMessage;
