import React, { Component } from 'react';
import pt from 'prop-types';
import './SearchBox.css';

class SearchBox extends Component {
    constructor(props) {
        super(props);
        this.state = { value: '', items: [] };
    }

    render() {
        return (
            <div className="SearchBox" style={this.props.style}>
                <input style={this.props.inputStyle} value={this.state.value} placeholder={this.props.placeholder} onChange={(event) => this.handleChange(event)} />
                {this.renderItems()}
            </div>
        );
    }

    renderItems() {
        if (this.state.items.length === 0) {
            return null;
        }

        var items = this.state.items;
        if (items && this.props.resultsLimit > 0){
            items = items.slice(0, Math.min(this.props.resultsLimit, items.length));
        }

        return (
            <ul className="ItemList">
                {items.map ? items.map((item, i) => {
                    return (
                        <li key={i} onClick={(e) => { this.handleSelection(e, item) }}>{item.name || item}</li>
                    );
                }) : null}
            </ul>
        );
    }

    handleSelection(e, item) {
        this.setState({ selectedItem: item, value: item.name || item, items: [] });
        this.props.onSelected(item);
    }

    handleChange(e) {
        this.setState({ value: e.target.value, loading: true });
        if (this.props.onTextChanged) {
            this.props.onTextChanged(e.target.value);
        }
        this.props.onSearch(e.target.value, (items) => { this.handleSearchResults(items) });
    }

    handleSearchResults(items) {
        this.setState({ items: items || [], loading: false });
    }
}

SearchBox.defaultProps = {
    placeholder: '',
    resultsLimit: 0
};

SearchBox.propTypes = {
    onSelect: pt.func,
    onTextChanged: pt.func,
    onSearch: pt.func,
    resultsLimit: pt.number,
};

export default SearchBox
