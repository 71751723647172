const Gray = "#6E6065";
const Green = "#37990D";
const Yellow = "#F6A200";
const Red = '#e00201';
const Purple = '#6660c3';
const White = '#FFFFFF';
const Black = '#000000';
const CommercialGray = '#6E6065';
const ConfiguratorGray = '#c3c0bd';

module.exports = Object.freeze({
    Gray: Gray,
    Green: Green,
    Yellow: Yellow,
    Red: Red,
    Purple: Purple,
    PurpleTrain: Purple,
    Train: Gray,
    LocalBus: Green,
    RegionBus: Yellow,
    MixedBus: Gray,
    Black: Black,
    White: White,
    CommercialBus: CommercialGray,
    ConfiguratorHeader: ConfiguratorGray,
});
