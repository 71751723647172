import '../Monitor.css';
import 'url-search-params-polyfill';

import React, { Component } from 'react';

import Footer from './Footer.jsx';
import TrafficBox from './TrafficBox.jsx';

import { logo } from '../Images.js';

class App extends Component {

  constructor(props){
    super(props);
      this.state = { width: 0, height: 0, traffic: [] };
      this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

      this.fetchData();
      setTimeout(function () {
          window.location.reload(1);
      }, 1000 * 60 * 60 * 24);
    }

    fetchData() {
        var refetchData;
        fetch('api/Journey/searchjourneys', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': this.props.apiKey
            },
            body: JSON.stringify({
                stationKey: this.props.stationId,
                stationName: this.props.stationName,
                trafficTypes: JSON.parse(this.props.trafficTypes),
                arriving: this.props.departure === "1",
                stopPoint: this.props.stopPoint,
                currentTime: this.props.currentTime ? new Date(this.props.currentTime + "+0000"): null
            })
        })
        .then(response => {
            if( response.status === 401 ) {
                this.setState({ traffic: [], error: 'Unauthorized' })
                clearTimeout(refetchData);
                return response.json();
            } 
            this.setState({ traffic: [], error: undefined })
            return response.json()
        })
        .catch(error => this.setState({ error: error }))
        .then(data => {
            if (data && data.traffic) {
                (data.traffic || []).forEach((traffic) => {
                    traffic.journeys.forEach((journey) => {
                        journey.time = new Date(journey.time);
                        if (journey.updatedTime) {
                            journey.updatedTime = new Date(journey.updatedTime);
                        }
                    })
                });
                this.setState({ traffic: data.traffic });
            }
        });

        refetchData = setTimeout(() => {
            this.fetchData();
        }, 1000 * 60);
    }

    render() {
        var displayClass = (this.state.traffic.length > 1 && this.state.horizontal) ? "InlineBlock" : ""; 
        var trafficBoxWidth = 0;
        if (this.state.traffic.length <= 1 || !this.state.horizontal){
            trafficBoxWidth = this.state.width;
        } else {
            trafficBoxWidth = (this.state.width / 2) - 10;
        }

        var trafficBoxHeight = 0;
        const footerHeight = 55;
        if (this.state.traffic.length <= 1 || this.state.horizontal){
            trafficBoxHeight = this.state.height - footerHeight;
        } else {
            trafficBoxHeight = this.state.height / 2 - footerHeight;
        }

        var trafficBoxes = this.state.traffic.map((box, index) => {
            return (<TrafficBox isDepartures={box.departures} width={trafficBoxWidth} height={trafficBoxHeight} horizontalMode={this.state.horizontal} displayClass={displayClass} key={index} type={box.type} title={box.title} journeys={box.journeys}/>);
        });

        if( this.state.error && this.state.error === 'Unauthorized' ) {
            document.body.style = 'background: #f2efe9;';
        }

        return (
            <>
                {this.state.error === 'Unauthorized' && (
                    <div className="error-message-wrapper">
                        <p className="error-message__text">Ogiltig säkerhetsnyckel</p>
                        <p className="error-message__text">Vänligen kontakta Skånetrafikens företagsservice på 0771-77 75 75 för mer information</p>

                        <div className="skanetrafiken-logo--bottom-right">
                            <img style={{ height: 48 }} src={logo} alt="skånetrafiken"/>
                        </div>
                    </div>
                )}
                
                {!this.state.error && (
                    <div className="App">    
                        {trafficBoxes}
                        <Footer />
                        {this.state.error && this.state.error !== 'Unauthorized' && (<div style={{ position: 'absolute', bottom: 0, width: '100%', height: 40, textAlign: 'center' }}>
                            <div className="alert alert-danger" role="alert" style={{ width: 300, marginLeft: 'auto', marginRight:'auto'}}>
                                Fel uppstod vid vid uppdatering.
                            </div>
                        </div>)}
                    </div>
                )}
            </>
        );
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight, horizontal: window.innerWidth > window.innerHeight && window.innerWidth > 1280});
    }
}

export default App;

