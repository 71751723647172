export function getFormattedTime(date) {
    return ("00" + date.getHours()).substr(-2, 2) + ":" + ("00" + date.getMinutes()).substr(-2, 2);
}

export function addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
}

export function calcDiffMin(from, to) {
    var diff = to - from;
    var minutes = Math.round((diff / 1000) / 60);
    return minutes;
}

export function getFormattedNumMinutes(from, to) {
    var numMin = calcDiffMin(from, to);

    var hours = 0;
    while (numMin >= 60) {
        numMin -= 60;
        hours++;
    }
    if (numMin <= 0 && hours <= 0)
        return "0 min";
    else {
        var result = "";
        // If we have hours exceeding 0 add these:
        if (hours > 0) {
            result = hours + "h";
        }
        // If we have minutes that exceed 0 add these to result:
        if (numMin > 0) {
            result += " " + numMin + " min";
        }
        return result;
    }
}

function getNumLines(textDiv) {
    var divHeight = textDiv.offsetHeight;
    var lineHeight = parseInt(textDiv.style.lineHeight);
    var lines = divHeight / lineHeight;
    return lines;
}

export function scaleToFit(textDiv) {
    var failsafe = 0;
    textDiv.style.lineHeight = "12px";
    while (getNumLines(textDiv) > 1 && failsafe < 200) {
        var style = window.getComputedStyle(textDiv, null).getPropertyValue('font-size');
        var fontSize = parseFloat(style);
        textDiv.style.fontSize = (fontSize - 1) + 'px';
        failsafe++;
    }
}
