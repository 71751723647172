import '../Monitor.css';

import React, { Component } from 'react';

import { CanceledBox } from './CanceledBox';
import Colors from './Colors.js';
import JourneyCanceledMessage from './JourneyCanceledMessage.jsx';
import JourneyMessage from './JourneyMessage.jsx';
import { getFormattedNumMinutes, getFormattedTime } from './Utilities';


class TrainJourneyItem extends Component {
  renderIcon() {
    var pathOne = "M4.8,40C2.2,40,0,37.9,0,35.2V4.8C0,2.1,2.2,0,4.8,0L20,0v40L4.8,40z";
    var pathTwo = "M20,35.3C18.6,24.5,10,0,0,0h0v40h0h15.2C17.8,40,20.3,37.9,20,35.3z";
    var pathThree = "M4.8,40C2.2,40,0,37.9,0,35.2V4.8C0,2.1,2.2,0,4.8,0L20,0v40L4.8,40z";
    var pathFour = "M20,35.3C18.6,24.5,10,0,0,0h0v40h0h15.2C17.8,40,20.3,37.9,20,35.3z";

    var inlineAbsolute = {display: 'inline-block', position: 'absolute'}; 
    var absolute = {position: 'absolute'};
    var lineHeight = {lineHeight: '40px'};
    var left = {left: '15px'};
    var scaling = {height: 40, transform: 'scale(0.90)', marginLeft: 6};

    var pagatagStyle = {...absolute, ...lineHeight, ...left, ...{fontSize: '17pt', marginLeft: '4px', backgroundColor: Colors.PurpleTrain}};

    var innerIconHtml;
      if (this.props.journey.type === "Pågatåg" || this.props.journey.type === "Pågatågen" || this.props.journey.type === "Pågatåg Express") {
        innerIconHtml = (
            <div className="svgTrainIcons pagatag" style={scaling}>
                <div style={inlineAbsolute}><svg className="svgPagatagIcon" style={{...absolute, fill: Colors.PurpleTrain}} version="1.1" width="20px" height="40px" viewBox="0 0 20 40"><path d={pathOne}/></svg></div>
                <div className="svgPlateTextPagatag pagatag" style={pagatagStyle}></div>
                <div style={{...inlineAbsolute, ...{ left: '113px'}}}><svg className="svgPagatagIcon" style={{...absolute, fill: Colors.PurpleTrain}} version="1.1" width="20px" height="40px" viewBox="0 0 20 40"><path d={pathTwo}/></svg></div>
            </div>
        );
    } else {
        innerIconHtml = (
            <div className={"svgTrainIcons " + this.props.journey.type === "Öresundståg" ? "oresundstag" : "krosatag"} style={scaling}>
                <div style={inlineAbsolute}><svg className="svgOresundstagIcon" style={{...absolute, fill: Colors.Train}} version="1.1" width="20px" height="40px" viewBox="0 0 20 40"><path d={pathThree}/></svg></div>
                <div className={this.props.journey.type === "Öresundståg" ? "svgPlateTextOresundstag oresundstag" : "svgPlateTextKrosatag krosatag"} style={{...absolute, ...lineHeight, ...left, ...{backgroundColor: Colors.Train, fontSize: '13pt', marginLeft: '-5px'}}}></div>
                <div style={{...inlineAbsolute, ...{left: '113px'}}}><svg className="svgOresundstagIcon" style={{...absolute, fill: Colors.Train}} version="1.1" width="20px" height="40px" viewBox="0 0 20 40"><path d={pathFour}/></svg></div>
            </div>
        );
    }

    return (   
      <div className="iconContainer">
          {innerIconHtml}
      </div>
    );
  }

  renderInfoMessage(){
    return (
      <tr>
        <td colSpan="7">
          <JourneyMessage text={this.props.journey.message} width={this.props.width}/>
        </td>
      </tr>
    );
  }

  renderCanceledMessage(){
    return (
      <JourneyCanceledMessage textCols={7} canceledSignCols={0} showCanceledSign={false} text={this.props.journey.message} width={this.props.width}/>
    );
  }

  renderMessage() {
    if (this.props.journey.canceled) {
      return this.renderCanceledMessage();
    }
    else if (this.props.journey.message){
      return this.renderInfoMessage();
    }
    return null;
  }

  getBackgroundColor() {
    return this.props.index % 2 !== 0 ? "#ffffff" : "#e2e5ec";
  }

  renderCountDownTime(journey) {
    if (journey.canceled) {
        return (<CanceledBox />);
    }
    else if (journey.awaitTime) {
        return '-- min';
    }
    else {
        var countdownTime = getFormattedNumMinutes(new Date(), this.props.journey.updatedTime || this.props.journey.time);
        return countdownTime;
    }
  }

  render() {
    var time = getFormattedTime(this.props.journey.time);
    var updatedTime = this.props.journey.updatedTime ? getFormattedTime(this.props.journey.updatedTime) : "";

    var directionMaxWidth = (this.props.width || 584) - 584;

    return (
      <tbody style={{background: this.getBackgroundColor()}}>
        <tr className="JourneyItem TrainJourneyItem">
    	  <td className="JourneyItemIcon" style={{minWidth: 130, maxWidth: 130}}>{this.renderIcon()}</td>
          <td className="Direction" style={{maxWidth: directionMaxWidth}}>{this.props.journey.direction}</td>
          <td className={this.props.journey.canceled || this.props.journey.updatedStopPoint ? "changed" : ""}>{this.props.journey.platform}</td>
          <td className={this.props.journey.canceled ? "changed" : ""}>{this.props.journey.updatedStopPoint}</td>
          <td className={this.props.journey.updatedTime || this.props.journey.canceled || this.props.journey.awaitTime ? "changed" : ""}>{time}</td>
          <td className="UpdatedTime">{updatedTime}</td>
          <td className="TimeCountDown">{ this.renderCountDownTime(this.props.journey) }</td>
        </tr>
        {this.renderMessage()}
      </tbody>
    );
  }
}

export default TrainJourneyItem;
