import React, { Component } from 'react';
import '../Monitor.css';

class BusTableHeader extends Component {
  render() {
  	var directionText = this.props.isDepartures ? "Mot": "Mot";
    return (
      <tr className="BusTableHeader JourneyListHeader">
		    <th>Linje</th>
            <th>{directionText}</th>
            <th>Läge</th>
            <th className="UpdatedCol"></th>
            <th style={{textAlign: 'right'}}>Om</th>
      </tr>
    );
  }
}

export default BusTableHeader;
