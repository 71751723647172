import React, { Component } from 'react';
import '../Monitor.css';

class TrainTableHeader extends Component {
  render() {
  	var directionText = this.props.isDepartures ? "Mot" : "Mot";
    var timeText = this.props.isDepartures ? "Avgår" : "Ankommer";
    return (
      <tr className="TrainTableHeader JourneyListHeader">
		    <th>Tåg</th>
            <th>{directionText}</th>
            <th>Spår</th>
            <th className="UpdatedCol"></th>
            <th>{timeText}</th>
            <th className="UpdatedCol"></th>
            <th style={{textAlign: 'right'}}>Om</th>
      </tr>
    );
  }
}

export default TrainTableHeader;
