import React, { Component } from 'react';
import Marquee from 'react-marquee';

class JourneyMessage extends Component {
  constructor(props){
	super(props);
	this.state = {overThreeRows: false};
  }
  render() {
  	var textAlign = this.props.text.length > 60 ? "left" : "center";

    const charactersPerWidth = 0.093; 
    var overThreeRows = this.props.text.length / charactersPerWidth / 3 > (this.props.width-60);

    return (
      <div className="JourneyMessage" style={{background: "#f7ca0f", textAlign, maxWidth: (this.props.width||60)-60}}>
      	{overThreeRows ? <Marquee hoverToStop loop text={this.props.text}/> : this.props.text}
      </div>
    );
  }
}

export default JourneyMessage;
