import React, { Component } from 'react';
import '../Monitor.css';
import Header from './Header.jsx'
import JourneyList from './JourneyList.jsx'

class TrafficBox extends Component {
  render() {
	const headerHeight = 90;

    return (
      <div className={"TrafficBox " + this.props.displayClass} style={{width: this.props.width || 0}}>
        <Header type={this.props.type} title={this.props.title} width={this.props.width} horizontalMode={this.props.horizontalMode}/>
        <JourneyList journeys={this.props.journeys} type={this.props.type} isDepartures={this.props.isDepartures} width={this.props.width} height={this.props.height - headerHeight}/>
      </div>
    );
  }
}

export default TrafficBox;
