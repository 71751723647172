import '../Monitor.css';

import React, { Component } from 'react';

import JourneyCanceledMessage from './JourneyCanceledMessage.jsx';
import JourneyMessage from './JourneyMessage.jsx';
import { getFormattedNumMinutes, getFormattedTime } from './Utilities';

class MiscTrafficJourneyItem extends Component {
  renderInfoMessage(){
    return (
      <tr>
        <td colSpan="5">
          <JourneyMessage text={this.props.journey.message} width={this.props.width}/>
        </td>
      </tr>
    );
  }
  renderCanceledMessage(){
    return (
          <JourneyCanceledMessage textCols={4} CanceledSignCols={1} text={this.props.journey.message} width={this.props.width}/>
    );
  }
  renderMessage() {
    if (this.props.journey.canceled) {
      return this.renderCanceledMessage();
    }
    else if (this.props.journey.message){
      return this.renderInfoMessage();
    }
    return null;
  }
  getBackgroundColor() {
    return this.props.index % 2 !== 0 ? "#ffffff" : "#e2e5ec";
  }
  render() {
    
    var countdownTime = ""
    if (this.props.journey.canceled) {
      countdownTime = getFormattedTime(this.props.journey.updatedTime || this.props.journey.time);
    } else {
      countdownTime = getFormattedNumMinutes(new Date(), this.props.journey.updatedTime || this.props.journey.time);
    }
    
    var directionMaxWidth = (this.props.width || 400) - 400;

    return (
      <tbody style={{background: this.getBackgroundColor()}}>
        <tr className="JourneyItem MiscTrafficJourneyItem">
          <td></td>
          <td className="Direction" style={{maxWidth: directionMaxWidth}}>{this.props.journey.direction}</td>
          <td className={(this.props.journey.canceled || this.props.journey.updatedStopPoint ? "changed" : "") + " Platform"}>{this.props.journey.platform}</td>
          <td className={(this.props.journey.canceled ? "changed" : "") + " Platform"}>{this.props.journey.updatedStopPoint}</td>
          <td className={(this.props.journey.canceled ? "changed" : "") + " TimeCountDown"}>{countdownTime}</td>
        </tr>
        {this.renderMessage()}
        
      </tbody>
    );
  }
}

export default MiscTrafficJourneyItem;
