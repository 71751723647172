import React, { Component } from 'react';

class JourneyListFullscreenMessage extends Component {
	render() {
		return (
			<div className="JourneyListFullscreenMessage" style={{minHeight: this.props.height}}>
				<div className="JourneyListFullscreenMessageBox">
					<div className="JourneyListFullscreenMessageHeader">{this.props.header}</div>
					<div className="JourneyListFullscreenMessageBody">{this.props.body}</div>
				</div>
			</div>
		);
	}
}

export default JourneyListFullscreenMessage;
